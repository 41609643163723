<template>
  <div class="p-4">
    <CollapseContainer
      title="Product Catalog Configuration"
      variant="secondary"
      border
      :open="true"
    >
      <div class="p-2">
        <ul>
          <li>Catalog's name: {{ catalog.name }}</li>
          <li v-if="!!withBrandAPI">
            Brand API URL: {{ catalog.brandAPiUrl }}
          </li>
          <li v-if="!!withBrandAPI">Template: {{ catalog.template }}</li>
        </ul>
      </div>
    </CollapseContainer>
    <section class="mt-3">
      <ProductList
        :with-brand-api="withBrandAPI"
        :catalog-connector-id="catalog.id"
        :country-code="catalog.countryCode"
        :environment="environment"
      />
    </section>
  </div>
</template>

<script>
  import Functions from '@/firebase/functions'
  export default {
    name: 'CatalogConfigView',
    components: {
      CollapseContainer: () =>
        import('@/components/containers/CollapseContainer'),
      ProductList: () =>
        import('@/components/catalog-configuration/CatalogProductList'),
    },
    data() {
      return {
        catalog: {
          name: '',
          brandAPiUrl: '',
          template: '',
          countryCode: ''
          },
        environment:this.$route.params.environment,
        products: {},
        withBrandAPI: true,
        options: [{ routeName: 'products', text: 'PRODUCTS' }],
      }
    },

    async mounted() {
      const { name, apiUri, template, id, countryCode } =
      await Functions.getCatalogById(this.$route.params.id, this.environment)

      this.withBrandAPI = template !== 'Local'
      this.catalog = { name, brandAPiUrl: apiUri, template, id, countryCode }
    },
  }
</script>

<style></style>
