import {
  TILE_TAGS_COLOR,
  CLASSIC_TAG_BACKGROUND,
  LIGHT_TAG_BACKGROUND,
  DARK_TAG_BACKGROUND,
} from '@/constants/ColorConstants'

export enum USER_THEME {
  CLASSIC = 'classic',
  LIGHT = 'light',
  DARK = 'dark',
}

export function defaultTagColor(userTheme: string) {
  switch (userTheme) {
    case 'classic':
      return CLASSIC_TAG_BACKGROUND
    case 'light':
      return LIGHT_TAG_BACKGROUND
    case 'dark':
      return DARK_TAG_BACKGROUND
    default:
      return TILE_TAGS_COLOR
  }
}

export type mainCSSColorVariableName =
  | '--success'
  | '--primary'
  | '--secondary'
  | '--danger'
  | '--warning'
  | '--app-background-font-color'
  | '--app-background-color'
  | '--info'

export function getMainColorCSSVariable(name: mainCSSColorVariableName) {
  return window.getComputedStyle(document.body).getPropertyValue(name)
}
