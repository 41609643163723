import { getDocumentsInCollection } from '@/service/FirebaseService'
import { createDataloader } from '@/service/DataloaderService'
import { Scope } from '@/models'
import DataLoader from 'dataloader'

export const RESOURCES = {
  TENANTS: 'TENANTS',
  CUSTOMERS: 'CUSTOMERS',
  APPLICATIONS: 'APPLICATIONS',
  COUNTRIES: 'COUNTRIES',
  ENVIRONMENTS: 'ENVIRONMENTS',
  TOUCHPOINTS: 'TOUCHPOINTS',
  TYPES: 'TYPES',
  BRANDS: 'BRANDS',
  CRITERIAS: 'CRITERIAS',
  FLAGS: 'FLAGS',
  HERO_INGREDIENTS: 'HERO_INGREDIENTS',
  CRM_CODES: 'CRM_CODES',
  APP_VERSIONS: 'APP_VERSIONS',
  ACTIVE_ROUTES: 'ACTIVE_ROUTES',
}

export type RESOURCE = (typeof RESOURCES)[keyof typeof RESOURCES]

export type ResourceConfigValue = {
  collectionName: string
  stateKey?: string
}

export const resourcesConfig: Record<string, ResourceConfigValue> = {
  [RESOURCES.TENANTS]: {
    collectionName: 'tenants',
  },
  [RESOURCES.CUSTOMERS]: {
    collectionName: 'customers',
  },
  [RESOURCES.APPLICATIONS]: {
    collectionName: 'applications',
  },
  [RESOURCES.COUNTRIES]: {
    collectionName: 'countries',
  },
  [RESOURCES.ENVIRONMENTS]: {
    collectionName: 'environments',
    stateKey: 'envs',
  },
  [RESOURCES.TOUCHPOINTS]: {
    collectionName: 'touchpoints',
  },
  [RESOURCES.TYPES]: {
    collectionName: 'types',
  },
  [RESOURCES.BRANDS]: {
    collectionName: 'brands',
  },
  [RESOURCES.CRITERIAS]: {
    collectionName: 'criterias',
  },
  [RESOURCES.FLAGS]: {
    collectionName: 'flags',
  },
  [RESOURCES.HERO_INGREDIENTS]: {
    collectionName: 'heroingredients',
  },
  [RESOURCES.CRM_CODES]: {
    collectionName: 'crmcodes',
  },
  [RESOURCES.APP_VERSIONS]: {
    collectionName: 'appversions',
  },
}

const resourceNamesByCollectionName: Record<string, string> = Object.entries(
  resourcesConfig,
).reduce((indexed, [resourceName, { collectionName }]) => {
  return {
    ...indexed,
    [collectionName]: resourceName,
  }
}, {})

export const getResourceName = (collectionName: string) => {
  return resourceNamesByCollectionName[collectionName]
}

export const filterHasMultiTenant =
  (scopeTenants: Scope) =>
  (obj: Record<string, any>): boolean => {
    return (
      !obj.tenants ||
      !obj.tenants.length ||
      (scopeTenants?.included?.includes('*') &&
        obj.tenants.some(
          (tenant) => !scopeTenants?.excluded?.includes(tenant.id),
        )) ||
      obj.tenants.some((tenant) => scopeTenants?.included?.includes(tenant.id))
    )
  }

export const filterHasMonoTenant =
  (scopeTenants: Scope) =>
  (obj: Record<string, any>): boolean => {
    return (
      !obj.tenant?.id ||
      (scopeTenants.included.includes('*') &&
        !scopeTenants.excluded.includes(obj.tenant.id)) ||
      scopeTenants.included.includes(obj.tenant.id)
    )
  }

export const getResourceStateKey = (
  resourceConfig: ResourceConfigValue,
): string => {
  const { stateKey: configStateKey, collectionName } = resourceConfig
  return configStateKey || collectionName
}

export type GetDocumentsInCollectionDataloaderKey = {
  collectionName: string
  getActiveDocuments?: boolean
  scope?: Scope
}

export const getDocumentsInCollectionLoader = () =>
  createDataloader<
    GetDocumentsInCollectionDataloaderKey,
    Record<string, any>[]
  >(
    (keys) => {
      return Promise.all(
        keys.map((key) => {
          const { collectionName, getActiveDocuments, scope } = key
          return getDocumentsInCollection(
            collectionName,
            getActiveDocuments,
            null,
            scope,
          )
        }),
      )
    },
    { areKeysObjects: true },
  )

export interface FetchCollectionInput<T> {
  documentsInCollectionLoader: DataLoader<
    GetDocumentsInCollectionDataloaderKey,
    Record<string, any>[],
    string
  >
  collectionName: string
  getActiveDocuments?: boolean
  scopeFn?: (collectionName: string) => Scope
  afterFn?: () => void
  sortFn?: (result: T, resultB: T) => number
  filterFn?: (result: T) => T
}

export const fetchCollection = async <T>({
  documentsInCollectionLoader,
  collectionName,
  getActiveDocuments,
  scopeFn,
  afterFn,
  sortFn,
  filterFn,
}: FetchCollectionInput<T>) => {
  const scope = typeof scopeFn === 'function' ? scopeFn(collectionName) : null

  let results = await documentsInCollectionLoader.load({
    collectionName,
    getActiveDocuments,
    scope,
  })

  if (filterFn) results = results?.filter(filterFn)
  if (sortFn) results = results?.sort(sortFn)
  if (afterFn) afterFn()

  return results
}
