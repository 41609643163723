<template>
  <b-select
    :value="environment"
    class="col-2 d-flex ml-auto"
    @change="changeEnv"
  >
    <b-select-option
      v-if="showAllEnvs || hasStagingData"
      value="staging"
      >Staging</b-select-option
    >
    <b-select-option
      v-if="showAllEnvs || hasProductionData"
      value="production"
      >Production</b-select-option
    >
  </b-select>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'ProductAPIConfigurationSelectEnvironment',

    props: {
      showAllEnvs: Boolean,
    },

    computed: {
      ...mapGetters('productApiSettings', [
        'hasProductionData',
        'hasStagingData',
      ]),
      environment() {
        return this.$route.params.environment ?? 'staging'
      },
    },

    methods: {
      changeEnv(value) {
        if (this.environment === value) return
        this.$router.push({
          name: this.$route.name,
          params: {
            ...this.$route.params,
            environment: value,
          },
        })
      },
    },
  }
</script>
