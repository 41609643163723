import partition from 'lodash/fp/partition'
import get from 'lodash/fp/get'
import Functions, {
  SearchProductsPagination,
  SearchProductsParams,
} from '@/firebase/functions'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { BHProductDTO, ProductStats, ProductStatsValue } from '@/dtos/BHProduct.dto'
import { Profile } from '@/types/Profile'

export type IndexedStatsValue = {
  yes: number
  no: number
}

export type BHCalibrationStatus = {
  isActive: boolean
  exists: boolean
  isValidated: boolean
  skip: boolean
  isActiveWithouCalibration: boolean
}

export type FormattedProduct = {
  preview: string | null
  sku: {
    route: {
      name: string
      params: {
        id: string
      }
    }
    label: string
  }
  id: string
  label: string
  calibration: string | null
  errors: string
  price: string | null
  pdpUrl: {
    label: string
    url: string
  }
  heroIngredients: any[]
  flags: any[]
  profilesLinked: Profile[]
  debugInfo: Record<string, any>
}

export type ProductsState = {
  isLoading: boolean
  isStatsLoading: boolean
  previousSearchParams?: SearchProductsParams
  previousAppRouteId: string | null
  previousAllProductsAppRouteId: string | null
  products: any[]
  pagination: SearchProductsPagination
  indexedStats: {
    isFound: IndexedStatsValue
    isExist: IndexedStatsValue
    isCalibrated: IndexedStatsValue
    isActiveWithoutCalibration: IndexedStatsValue
  }
  detailsConfigLoadedForAppRouteId: string | null
  productDetails: BHProductDTO | null,
  catalogProduct: BHProductDTO | null,
  allProductsForSearchAndReplace: any[]
}

const defaultIndexedStatsValue = {
  yes: 0,
  no: 0,
}

const defaultPagination = {
  nbResults: 0,
  nbPages: 0,
  currentPage: 0,
}

const defaultIndexedStats = {
  isFound: defaultIndexedStatsValue,
  isCalibrated: defaultIndexedStatsValue,
  isActiveWithoutCalibration: defaultIndexedStatsValue,
  isExist: defaultIndexedStatsValue,
}

const indexedStatsLabelSuccess = {
  isFound: 'found',
  isCalibrated: 'Calibrated',
  isActiveWithoutCalibration: 'Live',
  isExist: 'Found',
}

const initialState: ProductsState = {
  isLoading: false,
  isStatsLoading: false,
  previousSearchParams: null,
  previousAppRouteId: null,
  previousAllProductsAppRouteId: null,
  products: [],
  pagination: defaultPagination,
  indexedStats: defaultIndexedStats,
  detailsConfigLoadedForAppRouteId: null,
  productDetails: null,
  catalogProduct: null,
  allProductsForSearchAndReplace: [],
}

type ConfigValue = {
  label: string
  key?: string
  type?: 'link' | 'image' | 'tags' | 'default' | 'boolean' | 'number' | 'array',
  isEditable?: boolean
  getValueFn?: (value: Record<string, any>) => any
  transformValueFn?: (value: any) => any
}

const mapKeyDataConfig =
  (configValues: ConfigValue[]) => (productDetails: any) => {
    return configValues.map((c) => {
      const { key, getValueFn, transformValueFn } = c
      const getValue = getValueFn || get(key)
      const value = getValue(productDetails)

      return {
        ...c,
        value: transformValueFn ? transformValueFn(value) : value,
      }
    })
  }

const keyDataConfig: ConfigValue[] = [
  {
    key: 'sku',
    label: 'EAN / SKU',
  },
  {
    key: 'originalName',
    label: 'Original name',
    isEditable: false,
  },
  {
    key: 'productName',
    label: 'Product name',
    isEditable: true,
  },
  {
    key: 'subtitle',
    label: 'Subtitle',
    isEditable: false,
  },
  {
    key: 'name',
    label: 'Name',
    isEditable: true
  },
  {
    key: 'pdpUrl',
    label: 'pdp URL',
    isEditable: true,
    type: 'link',
  },
  {
    key: 'rangeCode',
    label: 'Range code',
    isEditable: true,
  },
  {
    key: 'rating',
    label: 'Rating',
    isEditable: false,
  },
  {
    key: 'ratingCount',
    label: 'Rating count',
    isEditable: false,
  },
  {
    key: 'daysOfUsage',
    label: 'Days of usage',
    isEditable: true,
  },
  {
    key: 'shortDescription',
    label: 'Short Description',
    isEditable: true,
  },
  {
    key: 'addToCartSku',
    label: 'Add to cart SKU',
    isEditable: true,
  },
  {
    key: 'heroIngredients',
    label: 'Hero ingredients',
    type: 'tags',
    isEditable: true,
  },
  {
    key: 'samplerUrl',
    label: 'Sampler URL',
    isEditable: true,
    type: 'link',
  },
  {
    key: 'flags',
    label: 'Flags',
    isEditable: true,
    type: 'tags',
  },
  {
    key: 'commercialFlags',
    label: 'Commercial Flags',
    isEditable: true,
    type: 'tags',
  },
  {
    key: 'modifaceVTOUpcName',
    label: 'Modiface VTO Upc Name',
    isEditable: true,
  },
  {
    key: 'shade',
    label: 'Shade',
    isEditable: true,
  },
  {
    key: 'family',
    label: 'Family',
    isEditable: true,
  },
  {
    key: 'disableForMP',
    label: 'Disable for MP',
    isEditable: true,
    type: 'boolean'
  },
  {
    key: 'productClaim',
    label: 'Product Claim',
    isEditable: true
  },
  {
    key: 'brandCode',
    label: 'Brand Code',
    isEditable: true
  },
  {
    key: 'packshotImageUrl',
    label: 'Packshot Image URL',
    isEditable: true,
    type: 'image'
  },
  {
    key: 'finish',
    label: 'Finish',
    isEditable: true
  },
  {
    key: 'collection',
    label: 'Collection',
    isEditable: true
  },
  {
    key: 'franchise',
    label: 'Franchise',
    isEditable: true
  },
  {
    key: 'shadeName',
    label: 'Shade Name',
    isEditable: true
  },
  {
    key: 'hexValue',
    label: 'Hex Value',
    isEditable: true
  },
  {
    key: 'isNew',
    label: 'is New',
    isEditable: true,
    type: 'boolean'
  },
  {
    key: 'shadeImagePath',
    label: 'Shade Image Path',
    isEditable: true,
    type: 'image'
  },
  {
    key: 'category',
    label: 'Category',
    isEditable: true
  },
  {
    key: 'eCommerce.enabled',
    label: 'Ecommerce Enabled',
    isEditable: true,
    type: 'boolean'
  },
  {
    key: 'eCommerce.clickToBuyUrl',
    label: 'Ecommerce Click To Buy URL',
    isEditable: true
  },
  {
    key: 'eCommerce.priceWithoutDiscount',
    type: 'number',
    label: 'Price Without Discount',
    isEditable: true
  },
  {
    key: 'eCommerce.color',
    label: 'eCommerce Color',
    isEditable: true
  },
  {
    key: 'eCommerce.price',
    label: 'Ecommerce Price',
    isEditable: false
  },
  {
    key: 'eCommerce.currency',
    label: 'Ecommerce Currency',
    isEditable: false
  },
  {
    key: 'eCommerce.orderable',
    label: 'Ecommerce Orderable',
    isEditable: false
  },
  {
    key: 'eCommerce.size',
    label: 'Ecommerce Size',
    isEditable: false
  },
  {
    key: 'eCommerce.availability',
    label: 'Ecommerce Availability',
    isEditable: false
  },
  {
    key: 'eCommerce.outOfStock',
    label: 'Ecommerce Out Of Stock',
    isEditable: false
  },
  {
    key: 'analytics.productMasterId',
    label: 'Analytics Product Master ID',
    isEditable: false
  },
  {
    key: 'analytics.productCategory',
    label: 'Analytics Product Category',
    isEditable: false
  },
  {
    key: 'analytics.brandAnalyticsTrigram',
    label: 'Analytics Brand Analytics Trigram',
    isEditable: false
  },
  {
    key: 'analytics.productVariant',
    label: 'Analytics Product Variant',
    isEditable: false
  },
  {
    key: 'analytics.outOfStock',
    label: 'Analytics Out Of Stock',
    isEditable: false
  },
  {
    key: 'analytics.serviceEngraving',
    label: 'Analytics Service Engraving',
    isEditable: false
  },
  {
    key: 'analytics.serviceVirtualTryOn',
    label: 'Analytics Service Virtual Try On',
    isEditable: false
  },
  {
    key: 'calibrationStatus',
    label: 'Calibration Status',
    isEditable: false
  },
  {
    key: 'pigmentation',
    label: 'Pigmentation',
    isEditable: false
  },
  {
    key: 'services',
    label: 'Services',
    isEditable: false,
    type: 'array'
  },
  {
    key: 'modifaceCMSData.calibration.isActive',
    label: 'is active',
    isEditable: false,
  },
  {
    key: 'modifaceCMSData.calibration.exists',
    label: 'exists',
    isEditable: false,
  },
  {
    key: 'modifaceCMSData.calibration.isValidated',
    label: 'is Validated',
    isEditable: false,
  },
  {
    key: 'modifaceCMSData.calibration.isActiveWithoutCalibration',
    label: 'is Active Without Calibration',
    isEditable: false,
  },
  {
    key: 'modifaceCMSData.pigmentation.reduction',
    label: 'reduction',
    isEditable: false,
  },
  {
    key: 'modifaceCMSData.pigmentation.threshold',
    label: 'threshold',
    isEditable: false,
  }

]

const findStatWithName = (label: string) => (stat: ProductStatsValue) =>
  label === stat.name

const state = { ...initialState }

const mutations: MutationTree<ProductsState> = {
  SET_LOADING: (state, isLoading: boolean) => {
    state.isLoading = isLoading
  },

  SET_STATS_LOADING: (state, isLoading: boolean) => {
    state.isStatsLoading = isLoading
  },

  SET_PREVIOUS_APP_ROUTE_ID: (state, appRouteId: string) => {
    state.previousAppRouteId = appRouteId
  },

  SET_PREVIOUS_ALL_PRODUCTS_APP_ROUTE_ID: (state, appRouteId: string) => {
    state.previousAllProductsAppRouteId = appRouteId
  },

  SET_SEARCH_PARAMS: (state, payload: SearchProductsParams) => {
    state.previousSearchParams = payload
  },

  SET_PRODUCTS: (
    state,
    payload: { products: any[]; pagination: SearchProductsPagination },
  ) => {
    state.products = payload.products
    state.pagination = payload.pagination
  },


  SET_STATS: (state, payload: ProductStats) => {
    const [[found], [notFound]] = partition(
      findStatWithName(indexedStatsLabelSuccess.isFound),
    )(payload.productFounds)
    const [[calibrated], [notCalibrated]] = partition(
      findStatWithName(indexedStatsLabelSuccess.isCalibrated),
    )(payload.productModifaceCalibrated)
    const [[liveCalibrated], [liveNotCalibrated]] = partition(
      findStatWithName(indexedStatsLabelSuccess.isActiveWithoutCalibration),
    )(payload.productModifaceCalibratedLive)
    const [[modifaceExists], [modifaceMissing]] = partition(
      findStatWithName(indexedStatsLabelSuccess.isExist),
    )(payload.productModifaceExists)

    state.indexedStats = {
      isFound: {
        yes: found?.score || 0,
        no: notFound?.score || 0,
      },
      isCalibrated: {
        yes: calibrated?.score || 0,
        no: notCalibrated?.score || 0,
      },
      isActiveWithoutCalibration: {
        yes: liveCalibrated?.score || 0,
        no: liveNotCalibrated?.score || 0,
      },
      isExist: {
        yes: modifaceExists?.score || 0,
        no: modifaceMissing?.score || 0,
      },
    }
  },

  SET_DETAILS_CONFIG_LOADED_FOR_APP_ROUTE_ID: (state, appRouteId: string) => {
    state.detailsConfigLoadedForAppRouteId = appRouteId
  },

  SET_ALL_PRODUCTS: (state, products: any[]) => {
    state.allProductsForSearchAndReplace = products
  },

  RESET_STATE: (state) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state = Object.assign(state, initialState)
  },
}

const actions: ActionTree<ProductsState, RootState> = {
  async searchProducts(
    { commit },
    {
      appRouteId,
      searchParams,
    }: { appRouteId: string; searchParams: SearchProductsParams },
  ) {
    const shouldRefreshStats = state.previousAppRouteId !== appRouteId

    commit('SET_LOADING', true)
    if (shouldRefreshStats) commit('SET_STATS_LOADING', true)

    const [{ results: products, pagination }, stats] = await Promise.all([
      Functions.searchProducts(appRouteId, searchParams),
      shouldRefreshStats ? Functions.getProductsStats(appRouteId) : undefined,
    ])

    commit('SET_PREVIOUS_APP_ROUTE_ID', appRouteId)
    commit('SET_SEARCH_PARAMS', searchParams)
    commit('SET_PRODUCTS', { products, pagination })

    if (stats) {
      commit('SET_STATS', stats)
      commit('SET_STATS_LOADING', false)
    }

    commit('SET_LOADING', false)
  },

  async searchProductsV3(
    { commit },
    {
      appRouteId,
      searchParams,
    }: { appRouteId: string; searchParams: SearchProductsParams },
  ) {
    const shouldRefreshStats = state.previousAppRouteId !== appRouteId

    commit('SET_LOADING', true)
    if (shouldRefreshStats) commit('SET_STATS_LOADING', true)

    let stats

    const { results: products, pagination } = await Functions.searchProductsV3(
      appRouteId,
      searchParams,
    ) //TODO To be completed



    commit('SET_PREVIOUS_APP_ROUTE_ID', appRouteId)
    commit('SET_SEARCH_PARAMS', searchParams)
    commit('SET_PRODUCTS', { products, pagination })

    if (stats) {
      commit('SET_STATS', stats)
      commit('SET_STATS_LOADING', false)
    }

    commit('SET_LOADING', false)
  },

  async setAllProducts({ commit }, appRouteId: string) {
    const shouldRefreshAllProducts =
      state.previousAllProductsAppRouteId !== appRouteId

    if (!shouldRefreshAllProducts) {
      return
    }

    commit('SET_LOADING', true)

    const [{ results: products }] = await Promise.all([
      Functions.searchProducts(appRouteId, {}),
    ])
    commit('SET_ALL_PRODUCTS', products)
    commit('SET_PREVIOUS_ALL_PRODUCTS_APP_ROUTE_ID', appRouteId)

    commit('SET_LOADING', false)
  },


  async loadProductDetailsV3(
    { state, commit, dispatch },
    { appRouteId, productId }: { appRouteId: string; productId: string },
  ) {
    const shouldLoadConfiguration = Boolean(
      !state.detailsConfigLoadedForAppRouteId ||
      state.detailsConfigLoadedForAppRouteId !== appRouteId,
    )

    if (shouldLoadConfiguration) {
      await dispatch('setCurrentRouteById', appRouteId, { root: true })
    }
    await Promise.all([
      dispatch('getProductDetailsV3', {
        appRouteId,
        sku: productId,
      }),
    ])

    if (shouldLoadConfiguration) {
      commit('SET_DETAILS_CONFIG_LOADED_FOR_APP_ROUTE_ID', appRouteId)
    }
  },

  async getProductDetailsV3(
    _,
    { appRouteId, sku }: { appRouteId: string; sku: string },
  ) {
    const { productLayer, catalogProduct } = await Functions.getProductDetailsV3(appRouteId, sku)
    state.productDetails = productLayer
    state.catalogProduct = catalogProduct
  },

  //TODO make env dynamic
  async getCatalogProductDetails(
    _,
    { catalogConnectorId, sku }: { catalogConnectorId: string; sku: string },
  ) {
    const res = await Functions.getProductFromCatalog("staging", catalogConnectorId, sku)
    state.catalogProduct = res

  }

}

const getters: GetterTree<ProductsState, RootState> = {
  getAllProducts: (state) => {
    return state.allProductsForSearchAndReplace ?? []
  },

  getProductLayers: (state) => {
    const { productDetails } = state
    if (!productDetails) return []

    return {
      productLayer: productDetails,
      keyDataConfig: mapKeyDataConfig(keyDataConfig)(productDetails)
    }
  },

  getCatalogProduct: (state) => {
    const { catalogProduct } = state
    if (!catalogProduct) return []

    return {
      product: catalogProduct,
      keyDataConfig: mapKeyDataConfig(keyDataConfig)(catalogProduct)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
