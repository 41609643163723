import partition from 'lodash/fp/partition'
import isNil from 'lodash/fp/isNil'
import negate from 'lodash/fp/negate'
import get from 'lodash/fp/get'
import Functions, {
  PrivateProductDetails,
  SearchProductsPagination,
  SearchProductsParams,
} from '@/firebase/functions'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/store'
import { ProductStats, ProductStatsValue } from '@/dtos/BHProduct.dto'
import { parseAppRouteId } from '@/utils/InstanceHelper'
import { CalibrationStatus } from '@/constants/CalibrationStatus.enum'
import { Profile } from '@/types/Profile'

export type IndexedStatsValue = {
  yes: number
  no: number
}

export type BHCalibrationStatus = {
  isActive: boolean
  exists: boolean
  isValidated: boolean
  skip: boolean
  isActiveWithouCalibration: boolean
}

export type FormattedProduct = {
  preview: string | null
  sku: {
    route: {
      name: string
      params: {
        id: string
      }
    }
    label: string
  }
  id: string
  label: string
  calibration: string | null
  errors: string
  price: string | null
  pdpUrl: {
    label: string
    url: string
  }
  heroIngredients: any[]
  flags: any[]
  profilesLinked: Profile[]
  debugInfo: Record<string, any>
}

export type ProductsState = {
  isLoading: boolean
  isStatsLoading: boolean
  previousSearchParams?: SearchProductsParams
  previousAppRouteId: string | null
  previousAllProductsAppRouteId: string | null
  products: any[]
  pagination: SearchProductsPagination
  indexedStats: {
    isFound: IndexedStatsValue
    isExist: IndexedStatsValue
    isCalibrated: IndexedStatsValue
    isActiveWithoutCalibration: IndexedStatsValue
  }
  detailsConfigLoadedForAppRouteId: string | null
  productSettings: Record<any, string> | null
  productDetails: PrivateProductDetails | null
  allProductsForSearchAndReplace: any[]
}

const defaultIndexedStatsValue = {
  yes: 0,
  no: 0,
}

const defaultPagination = {
  nbResults: 0,
  nbPages: 0,
  currentPage: 0,
}

const defaultIndexedStats = {
  isFound: defaultIndexedStatsValue,
  isCalibrated: defaultIndexedStatsValue,
  isActiveWithoutCalibration: defaultIndexedStatsValue,
  isExist: defaultIndexedStatsValue,
}

const indexedStatsLabelSuccess = {
  isFound: 'found',
  isCalibrated: 'Calibrated',
  isActiveWithoutCalibration: 'Live',
  isExist: 'Found',
}

const initialState: ProductsState = {
  isLoading: false,
  isStatsLoading: false,
  previousSearchParams: null,
  previousAppRouteId: null,
  previousAllProductsAppRouteId: null,
  products: [],
  pagination: defaultPagination,
  indexedStats: defaultIndexedStats,
  detailsConfigLoadedForAppRouteId: null,
  productSettings: null,
  productDetails: null,
  allProductsForSearchAndReplace: [],
}

type Maybe<T> = T | null | undefined

const isDefined = negate(isNil)

type ConfigValue = {
  label: string
  key?: string
  type?: string
  isEditable?: boolean
  getValueFn?: (value: Record<string, any>) => any
  transformValueFn?: (value: any) => any
}

const mapKeyDataConfig =
  (configValues: ConfigValue[]) => (productDetails: PrivateProductDetails) => {
    const { merged, overriden } = productDetails

    return configValues.map((c) => {
      const { key, getValueFn, transformValueFn } = c
      const getValue = getValueFn || get(key)
      const value = getValue(merged)

      return {
        ...c,
        value: transformValueFn ? transformValueFn(value) : value,
        isHighlighted: isDefined(getValue(overriden)),
      }
    })
  }

const transformBooleanToString = (value: Maybe<boolean>): Maybe<string> => {
  if (typeof value === 'boolean') return value ? 'Yes' : 'No'
  return value
}

const transformArrayStringsToString = (
  elements: Maybe<any[]>,
): Maybe<string> => {
  if (isNil(elements)) return elements
  return elements.join(', ')
}

const generalProductCol1Config = [
  {
    key: 'sku',
    label: 'EAN / SKU',
  },
  {
    key: 'name',
    label: 'Name',
    isEditable: false,
  },
  {
    key: 'originalName',
    label: 'Original name',
  },
  {
    key: 'subtitle',
    label: 'Subtitle',
    isEditable: false,
  },
  {
    key: 'active',
    label: 'Active',
  },
  {
    key: 'isNew',
    label: 'Is new',
    isEditable: false,
    transformValueFn: transformBooleanToString,
  },
  {
    label: 'Service available',
    getValueFn: (merged) => merged?.services?.some((s) => s.available),
  },
  {
    key: 'franchise',
    label: 'Franchise',
    isEditable: false,
  },
  {
    key: 'category',
    label: 'Category',
    isEditable: false,
  },
  {
    key: 'family',
    label: 'Family',
    isEditable: false,
  },
  {
    key: 'collection',
    label: 'Collection',
    isEditable: false,
  },
  {
    key: 'finish',
    label: 'Finish',
    isEditable: false,
  },
  {
    key: 'hexValue',
    label: 'Hex value',
    isEditable: false,
  },
  {
    key: 'claim',
    label: 'Claim',
    isEditable: false,
  },
]

const generalProductCol2Config = [
  {
    key: 'packshotImageUrl',
    label: 'Packshot image',
    type: 'image',
  },
  {
    key: 'packshotImageUrl',
    label: 'Packshot URL',
    type: 'link',
    isEditable: false,
  },
  {
    key: 'pdpUrl',
    label: 'pdp URL',
    isEditable: false,
    type: 'link',
  },
  {
    key: 'brandCode',
    label: 'Brand',
    isEditable: false,
  },
  {
    key: 'brandLabel',
    label: 'Brand label',
  },
  {
    key: 'countryCode',
    label: 'Country code',
  },
  {
    key: 'priceWithoutDiscount',
    label: 'Price without discount',
    isEditable: false,
  },
  {
    key: 'rating',
    label: 'Rating',
  },
  {
    key: 'rating_Count',
    label: 'Rating count',
  },
]

const generalProductBottomConfig = [
  {
    key: 'shortDescription',
    label: 'Description',
    isEditable: false,
  },
  {
    key: 'heroIngredients',
    label: 'Hero ingredients',
    isEditable: false,
    transformValueFn: transformArrayStringsToString,
  },
]

const ecommerceCol1Config = [
  {
    key: 'eCommerce.priceWithoutDiscount',
    label: 'Price without discount',
    isEditable: false,
  },
  {
    key: 'eCommerce.price',
    label: 'Price',
  },
  {
    key: 'eCommerce.currency',
    label: 'Currency',
  },
  {
    key: 'eCommerce.size',
    label: 'Size',
  },
  {
    key: 'eCommerce.color',
    label: 'Color',
    isEditable: false,
  },
  {
    key: 'eCommerce.enabled', // TODO: check which field it is
    label: 'Ecommerce enabled',
    isEditable: false,
  },
]

const ecommerceCol2Config = [
  {
    key: 'eCommerce.orderable',
    label: 'Orderable',
    transformValueFn: transformBooleanToString,
  },
  {
    key: 'eCommerce.availability',
    label: 'Availability',
  },
  {
    key: 'eCommerce.outOfStock',
    label: 'Out of stock',
  },
  {
    key: 'eCommerce.clicktobuyUrl',
    label: 'Click to buy URL',
    isEditable: false,
  },
  {
    key: 'addToCartSku',
    label: 'Add to cart SKU',
  },
]

const analyticsCol1Config = [
  {
    key: 'analytics.originalName',
    label: 'Original name',
  },
  {
    key: 'analytics.productMasterId',
    label: 'Product master id',
  },
  {
    key: 'analytics.brandAnaltyticsTrigram',
    label: 'Brand analytics trigram',
  },
  {
    key: 'analytics.productCategory',
    label: 'Product category',
  },
]

const analyticsCol2Config = [
  {
    key: 'analytics.productVariant',
    label: 'Product variant',
  },
  {
    key: 'analytics.outOfStock',
    label: 'Out of stock',
  },
  {
    key: 'analytics.serviceEngraving',
    label: 'Service engraving',
  },
  {
    key: 'analytics.serviceVirtualtryon',
    label: 'Service virtual try on',
  },
]

const variationsConfig = [
  {
    key: 'samplerUrl',
    label: 'Sampler URL',
    isEditable: false,
    type: 'link',
  },
]

const flagsConfig = [
  {
    key: 'flags',
    label: 'Flags',
    isEditable: false,
    type: 'tags',
    getValueFn: (merged) => {
      const flags = merged.flags
      if (!Array.isArray(flags)) return flags

      return flags.map((flag) => ({
        label: flag,
        color: 'primary',
      }))
    },
  },
  {
    key: 'commercialFlags',
    label: 'Commercial Flags',
    isEditable: false,
    type: 'tags',
    getValueFn: (merged) => {
      const flags = merged.commercialFlags
      if (!Array.isArray(flags)) return flags

      return flags.map((flag) => ({
        label: flag,
        color: 'secondary',
      }))
    },
  },
]

const virtualtryonCol1Config = [
  {
    key: 'modifaceVTOUpcName',
    label: 'Modiface VTO Upc Name',
    isEditable: false,
  },
  {
    key: 'shadeName',
    label: 'Shade',
    isEditable: false,
  },
  {
    key: 'multiProductDisabled', // TODO: check which field it is
    label: 'Multi Product Disabled',
    transformValueFn: transformBooleanToString,
  },
  {
    key: 'colorMatchEnabled', // TODO: check which field it is
    label: 'Color match enabled',
    transformValueFn: transformBooleanToString,
  },
  {
    key: 'category',
    label: 'Category',
  },
]

const virtualtryonCol2Config = [
  {
    key: 'shadeImagePath',
    label: 'Shade image',
    type: 'image',
  },
  {
    key: 'shadeImagePath',
    label: 'Shade image path',
    type: 'link',
    isEditable: false,
  },
]

const modifaceCmsCalibrationConfig = [
  {
    key: 'modifaceCms.calibrationStatus.exists',
    label: 'Exist',
    transformValueFn: transformBooleanToString,
  },
  {
    key: 'modifaceCms.calibrationStatus.isActive',
    label: 'Active',
    transformValueFn: transformBooleanToString,
  },
  {
    key: 'modifaceCms.calibrationStatus.isValidated',
    label: 'Validated',
    transformValueFn: transformBooleanToString,
  },
]

const modifaceCmsPigmentationConfig = [
  {
    key: 'modifaceCms.pigmentation.reduction',
    label: 'Reduction',
  },
  {
    key: 'modifaceCms.pigmentation.threshold',
    label: 'Threshold',
  },
]

const mapKeyDataGeneralProductCol1Config = mapKeyDataConfig(
  generalProductCol1Config,
)
const mapKeyDataGeneralProductCol2Config = mapKeyDataConfig(
  generalProductCol2Config,
)
const mapKeyDataGeneralProductBottomConfig = mapKeyDataConfig(
  generalProductBottomConfig,
)

const mapKeyDataEcommerceCol1Config = mapKeyDataConfig(ecommerceCol1Config)
const mapKeyDataEcommerceCol2Config = mapKeyDataConfig(ecommerceCol2Config)

const mapKeyDataAnalyticsCol1Config = mapKeyDataConfig(analyticsCol1Config)
const mapKeyDataAnalyticsCol2Config = mapKeyDataConfig(analyticsCol2Config)

const mapKeyDataVariationsConfig = mapKeyDataConfig(variationsConfig)

const mapKeyDataFlagsConfig = mapKeyDataConfig(flagsConfig)

const mapKeyDataVirtualtryonCol1Config = mapKeyDataConfig(
  virtualtryonCol1Config,
)
const mapKeyDataVirtualtryonCol2Config = mapKeyDataConfig(
  virtualtryonCol2Config,
)

const mapKeyDataModifaceCmsCalibrationConfig = mapKeyDataConfig(
  modifaceCmsCalibrationConfig,
)
const mapKeyDataModifaceCmsPigmentationConfig = mapKeyDataConfig(
  modifaceCmsPigmentationConfig,
)

const findStatWithName = (label: string) => (stat: ProductStatsValue) =>
  label === stat.name

const state = { ...initialState }

const mutations: MutationTree<ProductsState> = {
  SET_LOADING: (state, isLoading: boolean) => {
    state.isLoading = isLoading
  },

  SET_STATS_LOADING: (state, isLoading: boolean) => {
    state.isStatsLoading = isLoading
  },

  SET_PREVIOUS_APP_ROUTE_ID: (state, appRouteId: string) => {
    state.previousAppRouteId = appRouteId
  },

  SET_PREVIOUS_ALL_PRODUCTS_APP_ROUTE_ID: (state, appRouteId: string) => {
    state.previousAllProductsAppRouteId = appRouteId
  },

  SET_SEARCH_PARAMS: (state, payload: SearchProductsParams) => {
    state.previousSearchParams = payload
  },

  SET_PRODUCTS: (
    state,
    payload: { products: any[]; pagination: SearchProductsPagination },
  ) => {
    state.products = payload.products
    state.pagination = payload.pagination
  },


  SET_STATS: (state, payload: ProductStats) => {
    const [[found], [notFound]] = partition(
      findStatWithName(indexedStatsLabelSuccess.isFound),
    )(payload.productFounds)
    const [[calibrated], [notCalibrated]] = partition(
      findStatWithName(indexedStatsLabelSuccess.isCalibrated),
    )(payload.productModifaceCalibrated)
    const [[liveCalibrated], [liveNotCalibrated]] = partition(
      findStatWithName(indexedStatsLabelSuccess.isActiveWithoutCalibration),
    )(payload.productModifaceCalibratedLive)
    const [[modifaceExists], [modifaceMissing]] = partition(
      findStatWithName(indexedStatsLabelSuccess.isExist),
    )(payload.productModifaceExists)

    state.indexedStats = {
      isFound: {
        yes: found?.score || 0,
        no: notFound?.score || 0,
      },
      isCalibrated: {
        yes: calibrated?.score || 0,
        no: notCalibrated?.score || 0,
      },
      isActiveWithoutCalibration: {
        yes: liveCalibrated?.score || 0,
        no: liveNotCalibrated?.score || 0,
      },
      isExist: {
        yes: modifaceExists?.score || 0,
        no: modifaceMissing?.score || 0,
      },
    }
  },

  SET_DETAILS_CONFIG_LOADED_FOR_APP_ROUTE_ID: (state, appRouteId: string) => {
    state.detailsConfigLoadedForAppRouteId = appRouteId
  },

  SET_ALL_PRODUCTS: (state, products: any[]) => {
    state.allProductsForSearchAndReplace = products
  },

  RESET_STATE: (state) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state = Object.assign(state, initialState)
  },
}

const actions: ActionTree<ProductsState, RootState> = {
  async searchProducts(
    { commit },
    {
      appRouteId,
      searchParams,
    }: { appRouteId: string; searchParams: SearchProductsParams },
  ) {
    const shouldRefreshStats = state.previousAppRouteId !== appRouteId

    commit('SET_LOADING', true)
    if (shouldRefreshStats) commit('SET_STATS_LOADING', true)

    const [{ results: products, pagination }, stats] = await Promise.all([
      Functions.searchProducts(appRouteId, searchParams),
      shouldRefreshStats ? Functions.getProductsStats(appRouteId) : undefined,
    ])

    commit('SET_PREVIOUS_APP_ROUTE_ID', appRouteId)
    commit('SET_SEARCH_PARAMS', searchParams)
    commit('SET_PRODUCTS', { products, pagination })

    if (stats) {
      commit('SET_STATS', stats)
      commit('SET_STATS_LOADING', false)
    }

    commit('SET_LOADING', false)
  },

  async searchProductsV3(
    { commit },
    {
      appRouteId,
      searchParams,
    }: { appRouteId: string; searchParams: SearchProductsParams },
  ) {
    const shouldRefreshStats = state.previousAppRouteId !== appRouteId

    commit('SET_LOADING', true)
    if (shouldRefreshStats) commit('SET_STATS_LOADING', true)

    let stats

    const { results: products, pagination } = await Functions.searchProductsV3(
      appRouteId,
      searchParams,
    ) //TODO To be completed



    commit('SET_PREVIOUS_APP_ROUTE_ID', appRouteId)
    commit('SET_SEARCH_PARAMS', searchParams)
    commit('SET_PRODUCTS', { products, pagination })

    if (stats) {
      commit('SET_STATS', stats)
      commit('SET_STATS_LOADING', false)
    }

    commit('SET_LOADING', false)
  },

  async setAllProducts({ commit }, appRouteId: string) {
    const shouldRefreshAllProducts =
      state.previousAllProductsAppRouteId !== appRouteId

    if (!shouldRefreshAllProducts) {
      return
    }

    commit('SET_LOADING', true)

    const [{ results: products }] = await Promise.all([
      Functions.searchProducts(appRouteId, {}),
    ])
    commit('SET_ALL_PRODUCTS', products)
    commit('SET_PREVIOUS_ALL_PRODUCTS_APP_ROUTE_ID', appRouteId)

    commit('SET_LOADING', false)
  },

  async loadProductDetails(
    { state, commit, dispatch },
    { appRouteId, productId }: { appRouteId: string; productId: string },
  ) {
    const shouldLoadConfiguration = Boolean(
      !state.detailsConfigLoadedForAppRouteId ||
      state.detailsConfigLoadedForAppRouteId !== appRouteId,
    )

    if (shouldLoadConfiguration) {
      await dispatch('setCurrentRouteById', appRouteId, { root: true })
    }
    await Promise.all([
      shouldLoadConfiguration
        ? dispatch('getProductSettings', { appRouteId })
        : undefined,
      dispatch('getProductDetails', {
        appRouteId,
        sku: productId,
      }),
    ])

    if (shouldLoadConfiguration) {
      commit('SET_DETAILS_CONFIG_LOADED_FOR_APP_ROUTE_ID', appRouteId)
    }
  },

  async getProductSettings(
    { rootState },
    { appRouteId }: { appRouteId: string },
  ) {
    const productApiConfig =
      rootState.approutes.currentRoute.data.appconfigurations?.productApi
    const { application, environment } = parseAppRouteId(appRouteId)
    const allowedEnvironments = ['staging', 'production']
    const defaultApplication = 'defaultService'

    if (
      !productApiConfig?.params ||
      !allowedEnvironments.includes(environment)
    ) {
      state.productSettings = null
      return
    }

    const { brand_code: brandCode, country_code: countryCode } =
      productApiConfig.params

    const { configuration } = await Functions.getProductConfiguration(
      environment as 'staging' | 'production',
      brandCode,
      countryCode,
    )

    const matchedAirtable =
      configuration.airtableUriList.find(
        ({ serviceName }) => serviceName === application,
      ) ||
      configuration.airtableUriList.find(
        ({ serviceName }) => serviceName === defaultApplication,
      )

    state.productSettings = {
      template: configuration.template,
      brandApiUrl: configuration.apiUri,
      airtableUrl: matchedAirtable?.url,
      productApiUrl: productApiConfig.url,
    }
  },

  async getProductDetails(
    _,
    { appRouteId, sku }: { appRouteId: string; sku: string },
  ) {
    const productDetails = await Functions.getPrivateProductDetails(
      appRouteId,
      sku,
    )

    state.productDetails = productDetails
  },

  unbindProducts({ commit }) {
    commit('RESET_STATE')
  },
}

const getters: GetterTree<ProductsState, RootState> = {
  getAllProducts: (state) => {
    return state.allProductsForSearchAndReplace ?? []
  },

  productSettingsKeyValues: (state) => {
    const { productSettings } = state
    if (!productSettings) return []

    return [
      {
        key: 'template',
        label: 'Template',
        value: productSettings.template,
      },
      {
        key: 'brandApiUrl',
        label: 'Brand API',
        value: productSettings.brandApiUrl,
        type: 'link',
      },
      {
        key: 'airtableUrl',
        label: 'Airtable',
        value: productSettings.airtableUrl,
        type: 'link',
      },
      {
        key: 'productApiUrl',
        label: 'Product API',
        value: productSettings.productApiUrl,
        type: 'link',
      },
    ]
  },

  generalDataCol1KeyValues: (state) => {
    const { productDetails } = state
    if (!productDetails) return []

    return mapKeyDataGeneralProductCol1Config(productDetails)
  },

  generalDataCol2KeyValues: (state) => {
    const { productDetails } = state
    if (!productDetails) return []

    return mapKeyDataGeneralProductCol2Config(productDetails)
  },

  generalDataBottomKeyValues: (state) => {
    const { productDetails } = state
    if (!productDetails) return []

    return mapKeyDataGeneralProductBottomConfig(productDetails)
  },

  ecommerceCol1KeyValues: (state) => {
    const { productDetails } = state
    if (!productDetails) return []

    return mapKeyDataEcommerceCol1Config(productDetails)
  },

  ecommerceCol2KeyValues: (state) => {
    const { productDetails } = state
    if (!productDetails) return []

    return mapKeyDataEcommerceCol2Config(productDetails)
  },

  analyticsCol1KeyValues: (state) => {
    const { productDetails } = state
    if (!productDetails) return []

    return mapKeyDataAnalyticsCol1Config(productDetails)
  },

  analyticsCol2KeyValues: (state) => {
    const { productDetails } = state
    if (!productDetails) return []

    return mapKeyDataAnalyticsCol2Config(productDetails)
  },

  variationsKeyValues: (state) => {
    const { productDetails } = state
    if (!productDetails) return []

    return mapKeyDataVariationsConfig(productDetails)
  },

  variationItems: (state) => {
    const { productDetails } = state
    if (!productDetails || !Array.isArray(productDetails.merged?.variations))
      return []

    return productDetails.merged.variations.flatMap((variation) => {
      const { sku, variationValues } = variation

      return variationValues.map(({ hexaCode, image }) => {
        return {
          image,
          keyValues: [
            {
              label: 'EAN / SKU',
              value: sku,
            },
            {
              label: 'hexaCode',
              value: hexaCode,
            },
          ],
        }
      })
    })
  },

  flagsKeyValues: (state) => {
    const { productDetails } = state
    if (!productDetails) return []

    return mapKeyDataFlagsConfig(productDetails)
  },

  virtualtryonCol1KeyValues: (state) => {
    const { productDetails } = state
    if (!productDetails) return []

    return mapKeyDataVirtualtryonCol1Config(productDetails)
  },

  virtualtryonCol2KeyValues: (state) => {
    const { productDetails } = state
    if (!productDetails) return []

    return mapKeyDataVirtualtryonCol2Config(productDetails)
  },

  modifaceCmsCalibrationKeyValues: (state) => {
    const { productDetails } = state
    if (!productDetails) return []

    return mapKeyDataModifaceCmsCalibrationConfig(productDetails)
  },

  modifaceCmsPigmentationKeyValues: (state) => {
    const { productDetails } = state
    if (!productDetails) return []

    return mapKeyDataModifaceCmsPigmentationConfig(productDetails)
  },

  getCalibrationStatusValue:
    () => (calibrationStatus?: BHCalibrationStatus) => {
      if (!calibrationStatus || calibrationStatus?.skip)
        return CalibrationStatus.NotApplicable
      else if (calibrationStatus?.isValidated)
        return CalibrationStatus.Calibrated
      else if (calibrationStatus?.isActive)
        return CalibrationStatus.InProductionWithoutCalibration
      else if (calibrationStatus?.exists) return CalibrationStatus.NotCalibrated
      else return CalibrationStatus.Missing
    },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
