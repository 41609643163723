import { mapGetters, mapMutations } from 'vuex'
import {
  pluralToSingular,
  firstCharacterToUppercase,
} from '@/utils/StringHelpers'
import { updateRef, addDocToCollection } from '@/service/FirebaseService'

const COLLECTIONS_IN_STATE = [
  'brands',
  'countries',
  'criterias',
  'customers',
  'flags',
  'heroingredients',
  'crmcodes',
  'applications',
  'approutes',
  'appversions',
  'tenants',
  'types',
  'touchpoints',
  'prescriptions',
  'envs',
]

export const DictionnariesCreationMixin = {
  props: {
    included: {
      type: Boolean,
      default: false,
    },
    edition: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    displayName: {
      type: String,
      default: null,
    },
    meta: {
      type: Object,
      default: null,
    },
  },

  components: {
    TitleContainer: () => import('@/components/containers/TitleContainer'),
    SubmitButton: () => import('@/components/form/SubmitButton'),
  },

  computed: {
    ...mapGetters(['getRootStateValueById']),

    collectionSingular() {
      const singular = pluralToSingular(this.stateKey)
      return firstCharacterToUppercase(singular)
    },

    title() {
      return `${this.collectionSingular} ${
        this.edition ? 'Edition' : 'Creation'
      }`
    },

    idState() {
      if (this.edition) return true
      if (this.idValue === null || this.idValue.length === 0) return null
      if (
        (COLLECTIONS_IN_STATE.includes(this.stateKey) &&
          this.getRootStateValueById({
            id: this.idValue,
            stateKey: this.stateKey,
          })) ||
        this.idAlreadyExists
      )
        return false
      return !this.idValidRegex || this.idValidRegex.test(this.idValue)
    },

    docPath() {
      return this.idValue ? `${this.stateKey}/${this.idValue}` : null
    },

    errorMessage() {
      if (this.idState === null) return null
      if (
        (COLLECTIONS_IN_STATE.includes(this.stateKey) &&
          this.getRootStateValueById({
            id: this.idValue,
            stateKey: this.stateKey,
          })) ||
        this.idAlreadyExists
      )
        return `Id ${this.idValue} already exists in ${this.stateKey} collection.`
      return this.defaultErrorMessage ?? 'Id does not math pattern.'
    },

    isSubmitDisabled() {
      return (
        this.idValue === null ||
        this.idValue?.length === 0 ||
        [null, false].includes(this.idState) ||
        this.displayNameValue === null ||
        this.displayNameValue?.length === 0
      )
    },
  },

  methods: {
    ...mapMutations(['addRootStateValue', 'updateRootStateValue']),

    async onSubmit() {
      this.appLoading(true)

      try {
        if (!this.docPath) throw new Error('Missing document path')
        if (!this.finalObject) throw new Error('Final object is not set')

        const meta = this.finalObject.meta

        if (this.edition) {
          await updateRef(this.docPath, this.finalObject)

          const newData = this.finalObject
          newData.meta = meta

          if (COLLECTIONS_IN_STATE.includes(this.stateKey))
            this.updateRootStateValue({
              id: this.idValue,
              newData: newData,
              key: this.stateKey,
            })
        } else {
          await addDocToCollection(
            null,
            this.stateKey,
            this.finalObject,
            null,
            this.idValue,
          )
          if (COLLECTIONS_IN_STATE.includes(this.stateKey))
            this.addRootStateValue({
              newData: this.finalObject,
              key: this.stateKey,
            })
        }

        this.setMessageInformations({
          message: `${this.collectionSingular} ${
            this.displayNameValue
          } has been successfully ${this.edition ? 'updated' : 'created'}`,
          state: 'success',
        })

        this.$emit('done', this.stateKey)
      } catch (error) {
        this.handleErrors({ error })
      } finally {
        this.appLoading(false)
      }
    },

    onReset() {
      if (this.edition && this.id) {
        this.$emit('delete', this.id)
      } else {
        this.resetValues()
      }
    },

    resetValues() {
      Object.keys(this.initialData).forEach((key) => {
        this.$data[key] = this.initialData[key]
      })
    },
  },
}
