<template>
  <div class="main-collection_container container-fluid">
    <TitleContainer
      v-if="!included"
      :title="title"
    />
    <b-form
      class="main-collection_form"
      @submit.prevent="onSubmit"
      @reset.prevent="onReset"
    >
      <div class="main-collection_fields">
        <b-form-group
          class="col-5 mb-2 pl-0"
          :label="`${name} id:`"
          label-for="input-id"
        >
          <b-form-input
            id="input-id"
            v-model="idValue"
            :disabled="isAppLoading || edition"
            :debounce="500"
            :state="idState"
            aria-describedby="input-id-feedback"
            required
          />
          <b-form-invalid-feedback id="input-id-feedback">
            {{ errorMessage }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="col-5 mb-2 pl-0"
          label="Display name:"
          label-for="input-display-name"
        >
          <b-form-input
            id="input-display-name"
            v-model.trim="displayNameValue"
            :disabled="isAppLoading"
            :debounce="500"
            required
          />
        </b-form-group>
        <b-row>
          <div :class="included ? 'action-button_container' : 'mt-4 pl-0'">
            <b-button
              type="reset"
              variant="link"
              class="text-danger"
              :class="!included ? 'w-100' : ''"
              >{{ edition ? 'Delete' : 'Reset' }}</b-button
            >
            <SubmitButton
              :disabled="isSubmitDisabled"
              :loading="isAppLoading"
              :class="included ? 'w-auto mb-auto ml-2' : ''"
            />
          </div>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
  import { DictionnariesCreationMixin } from '@/mixins/DictionnariesCreationMixin'

  const initialData = {
    idValue: null,
    displayNameValue: null,
  }

  export default {
    name: 'SimpleCreation',

    mixins: [DictionnariesCreationMixin],

    data() {
      return {
        ...initialData,
        initialData,
        idValidRegex: new RegExp(/^[A-Za-z-0-9]+$/),
        defaultErrorMessage:
          'Id must only contains letters without accentuation (lowercase or uppercase), numbers and - character.',
      }
    },

    computed: {
      finalObject() {
        return {
          id: this.idValue,
          displayName: this.displayNameValue,
        }
      },
    },

    mounted() {
      if (this.edition) {
        this.idValue = this.id
        this.displayNameValue = this.displayName
      }
    },
  }
</script>
