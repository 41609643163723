<script>
  import { mapActions } from 'vuex'
  import SimpleCreation from '@/components/admin/mainCollections/SimpleCreation'
  import { RESOURCES } from '@/service/ResourceService'

  export default {
    name: 'HeroIngredientCreation',
    extends: SimpleCreation,

    data() {
      return {
        stateKey: 'heroingredients',
        name: 'Hero Ingredients',
      }
    },

    async mounted() {
      await this.loadResources({ resourceNames: [RESOURCES.CRITERIAS] })
    },

    methods: {
      ...mapActions(['loadResources']),
    },
  }
</script>
