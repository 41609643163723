<template>
  <aside :class="sideMenuClasses">
    <div class="sidebar_sticky-container">
      <router-link to="/">
        <FirecampLogo
          :class="envLabel ? 'app-logo app-logo--header' : 'app-logo'"
          :text="appTitle"
          :logo-only="reduced"
        />
        <p
          :class="
            reduced
              ? 'env-name_container env-name_container--reduced'
              : 'env-name_container'
          "
          @hover.prevent
        >
          {{ envLabel }}
        </p>
      </router-link>

      <nav
        v-for="(item, index) in sidebarItems"
        :key="`item${index}`"
        class="sidebar_section"
      >
        <div
          v-b-toggle="`container${index}`"
          v-b-tooltip.hover.right.noninteractive="reduced ? item.label : ''"
          class="menu-label-dropdown"
        >
          <b-icon
            v-if="typeof item.icon === 'string'"
            :icon="item.icon"
          />
          <component
            :is="item.icon"
            v-else
          />
          <transition
            name="fade"
            mode="out-in"
          >
            <span
              v-if="!reduced"
              class="sidebar_label"
            >
              {{ item.label }}
            </span>
          </transition>
          <b-icon
            v-if="!reduced"
            class="sidebar-caret-down"
            :icon="
              applicationSectionVisible ? 'caret-up-fill' : 'caret-down-fill'
            "
            font-scale=".65"
            shift-v="-15"
          />
        </div>
        <b-collapse :id="`container${index}`">
          <div class="menu-dropdown">
            <component
              :is="subItem.to ? 'router-link' : 'div'"
              v-for="(subItem, subIndex) in item.subItems"
              :key="`subItems${subIndex}`"
              v-b-tooltip.hover.right.noninteractive="
                reduced ? subItem.label : ''
              "
              class="menu-dropdown_item"
              :to="subItem.to"
              @click="subItem.onClick ? subItem.onClick() : () => {}"
            >
              <b-icon
                v-if="typeof subItem.icon === 'string'"
                :icon="subItem.icon"
              />
              <component
                :is="subItem.icon"
                v-else
              />
              <transition
                name="fade"
                mode="out-in"
              >
                <span
                  v-if="!reduced"
                  class="sidebar_label"
                >
                  {{ subItem.label }}</span
                >
              </transition>
            </component>
          </div>
        </b-collapse>
      </nav>

      <div class="separator" />

      <div class="sidebar-button_container">
        <button @click="handleReduce">
          <b-icon :icon="reduced ? 'chevron-right' : 'chevron-left'" />
        </button>
      </div>
    </div>

    <transition
      name="fade"
      mode="out-in"
    >
      <span
        v-if="isProdEnv"
        class="version-release_label"
      >
        {{ versionReleaseLabel }}
      </span>
    </transition>

    <DuplicateAppModal
      v-if="showModalDuplicateAppDefault"
      @hidden="handleModal('modal-duplicate-app-default')"
    />

    <CopyPrescriptionModal
      v-if="showModalCopyAppPrescription"
      @hidden="handleModal('modal-copy-app-prescription')"
    />
  </aside>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import { generateShowDataValues } from '@/utils/ModalHelpers'
  import { ModalMixin } from '@/mixins/ModalMixin'

  import FirecampLogo from '@/components/misc/FirecampLogo'

  import DuplicateAppModal from '@/components/modals/DuplicateAppModal'
  import CopyPrescriptionModal from '@/components/modals/CopyPrescriptionModal'
  import IconProductConfigurationVue from './sidebar-icon/IconProductConfiguration.vue'
  import IconDupplicateInstanceVue from './sidebar-icon/IconDupplicateInstance.vue'
  import IconDocumentationManagementVue from './sidebar-icon/IconDocumentationManagement.vue'
  import IconDocumentationBoardVue from './sidebar-icon/IconDocumentationBoard.vue'
  import IconCatalogConfiguration from './sidebar-icon/IconCatalogConfiguration.vue'
  import IconUpgradeVue from './sidebar-icon/IconUpgrade.vue'
  import { RIGHTS } from '@/constants/UserConstants'

  const showDataValues = generateShowDataValues([
    'modal-copy-app-prescription',
    'modal-duplicate-app-default',
  ])

  export default {
    name: 'SidebarMenu',

    components: {
      DuplicateAppModal,
      CopyPrescriptionModal,
      FirecampLogo,
    },

    mixins: [ModalMixin],

    data() {
      return {
        ...showDataValues,
        appTitle: 'Firecamp',
        reduced: false,
        showApplicationMenu: false,
        showPrescriptionMenu: false,
        iconName: null,
        applicationSectionVisible: false,
        prescriptionSectionVisible: false,
        productManagementSectionVisible: false,
        adminSectionVisible: false,
        documentationSectionVisible: false,
      }
    },
    computed: {
      ...mapState({
        currentUser: (state) => state.user,
        isSideMenuReduced: (state) => state.user?.sideMenuReduced,
      }),

      ...mapGetters(['canUserDo', 'getLastReleaseDate', 'getVersion']),

      sideMenuClasses() {
        return {
          sidebar_container: true,
          'sidebar_container--reduced': this.reduced,
        }
      },

      envLabel() {
        switch (process.env.VUE_APP_ENV) {
          case 'local':
            return 'LOCAL'
          case 'development':
            return 'DEV'
          default:
            return null
        }
      },

      isProdEnv() {
        return process.env.VUE_APP_ENV === 'production'
      },

      formattedReleaseDate() {
        const rDate = this.getLastReleaseDate?.toDate()
        return rDate
          ? rDate.toLocaleDateString(navigator?.language)
          : 'no release date'
      },

      versionReleaseLabel() {
        return this.reduced
          ? `v${this.getVersion}`
          : `v${this.getVersion} - ${this.formattedReleaseDate}`
      },

      sidebarItems() {
        return [
          {
            label: 'Instances',
            icon: 'menu-button-wide-fill',
            subItems: [
              ...(this.canUserDo(RIGHTS.CREATE_ROUTE)
                ? [
                    {
                      label: 'Create instance',
                      icon: 'file-earmark-plus',
                      to: '/app/route/create',
                    },
                  ]
                : []),
              ...(this.canUserDo(RIGHTS.UPGRADE_ROUTE)
                ? [
                    {
                      label: 'Upgrade instance',
                      icon: IconUpgradeVue,
                      to: '/app/route/upgrade',
                    },
                  ]
                : []),
              ...(this.canUserDo(RIGHTS.DUPLICATE_ROUTE)
                ? [
                    {
                      label: 'Duplicate instance',
                      icon: IconDupplicateInstanceVue,
                      onClick: () =>
                        this.handleModal('modal-duplicate-app-default'),
                    },
                  ]
                : []),
            ],
          },
          {
            label: 'Prescriptions',
            icon: 'clipboard-data',
            subItems: [
              ...(this.canUserDo(RIGHTS.PRESCRIPTION_CREATE)
                ? [
                    {
                      label: 'Create',
                      icon: 'clipboard-plus',
                      to: { name: 'install-app-prescription' },
                    },
                  ]
                : []),
              ...(this.canUserDo(RIGHTS.PRESCRIPTION_COPY)
                ? [
                    {
                      label: 'Copy',
                      icon: 'intersect',
                      onClick: () =>
                        this.handleModal('modal-copy-app-prescription'),
                    },
                  ]
                : []),
            ],
          },
          ...(this.canUserDo(RIGHTS.PRODUCT_API_CONFIG)
            ? [
                {
                  label: 'Product Management',
                  icon: 'bag',
                  subItems: [
                    {
                      label: 'Product Configuration',
                      icon: IconProductConfigurationVue,
                      to: { name: 'product-api-config' },
                    },
                    ...(this.canUserDo(RIGHTS.IGNORE_MAINTENANCE_MODE) ? [{
                      label: 'Catalog Configuration',
                      icon: IconCatalogConfiguration,
                      to: '/catalog-configurations/staging',
                    }] : [] )
                  ],
                },
              ]
            : []),
          {
            label: 'Admin',
            icon: 'gear-wide-connected',
            subItems: [
              ...(this.canUserDo(RIGHTS.USER_MANAGEMENT)
                ? [
                    {
                      label: 'User management',
                      icon: 'person-lines-fill',
                      to: { name: 'admin-users' },
                    },
                  ]
                : []),
              ...(this.canUserDo(RIGHTS.DICTONARIES_MANAGEMENT)
                ? [
                    {
                      label: 'Dictionaries management',
                      icon: 'journal-medical',
                      to: { name: 'admin-dictionnaries' },
                    },
                  ]
                : []),
              ...(this.canUserDo(RIGHTS.APPLICATIONS_MANAGEMENT)
                ? [
                    {
                      label: 'Applications management',
                      icon: 'menu-button-wide',
                      to: { name: 'admin-applications-versions' },
                    },
                  ]
                : []),
              ...(this.canUserDo(RIGHTS.QUESTIONNAIRES_MANAGEMENT)
                ? [
                    {
                      label: 'Questionnaires management',
                      icon: 'patch-question',
                      to: { name: 'admin-questionnaires' },
                    },
                  ]
                : []),
            ],
          },
          {
            label: 'Documentation',
            icon: 'book',
            subItems: [
              ...(this.canUserDo(RIGHTS.DOCUMENTATION_BOARD)
                ? [
                    {
                      label: 'Documentation Board',
                      icon: IconDocumentationBoardVue,
                      to: { name: 'documentation-board' },
                    },
                  ]
                : []),
              ...(this.canUserDo(RIGHTS.DICTONARIES_MANAGEMENT)
                ? [
                    {
                      label: 'Documentation Management',
                      icon: IconDocumentationManagementVue,
                      to: { name: 'documentation-management' },
                    },
                  ]
                : []),
            ],
          },
        ].filter((item) => item.subItems.length > 0)
      },
    },

    watch: {
      isSideMenuReduced: {
        handler(newVal) {
          this.reduced = newVal
        },
        immediate: true,
      },
    },

    methods: {
      ...mapActions(['updateSideMenuReducedRef']),

      async handleReduce() {
        this.reduced = !this.reduced
        this.updateSideMenuReducedRef(this.reduced)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .sidebar_container {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    width: 255px;
    height: 100%;

    color: var(--primary-font-color);
    background-color: var(--primary);
    border-right: 1px solid var(--low-opacity-border);
    transition: width 0.2s ease-in-out;

    &--reduced {
      width: 46px;
    }
  }

  .sidebar_sticky-container {
    overflow: hidden;
    position: sticky;
    top: 0;
  }

  .app-logo {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 10px;
    max-height: 35px;
    width: 210px;
    transition: padding 0.2s ease-in-out 0.05s;

    &:hover {
      cursor: pointer;
    }
  }

  .env-name_container {
    position: absolute;
    top: 45px;
    text-align: right;
    width: 210px;
    font-family: Blair;
    font-size: 0.65rem;
    color: white;
    text-decoration: none;
    transition-property: width, top;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;

    &--reduced {
      width: 46px;
      top: 50px;
      text-align: center;
      font-size: 0.5rem;
    }
  }

  .version-release_label {
    bottom: 0;
    position: fixed;
    padding: 5px;
    align-self: center;

    font-size: 0.8rem;
    color: var(--primary-font-color);
  }

  .separator {
    border-bottom: 1px solid var(--low-opacity-border);
    margin-bottom: 0.5rem;
  }

  .sidebar-button_container {
    padding-left: 10px;

    button {
      background-color: transparent;
      border: none;
      color: var(--primary-font-color);
      padding: 5px;
    }
  }

  .sidebar_section {
    border-top: 1px solid var(--low-opacity-border);
    user-select: none;
  }

  .sidebar_label {
    padding-left: 5px;
  }

  .sidebar-caret-down {
    position: absolute;
    right: 10px;
  }

  .menu-label-dropdown {
    padding: 15px 12px;

    .menu-label-link {
      color: var(--primary-font-color);

      &:hover {
        color: var(--secondary-font-color);
        text-decoration: none;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: rgba($color: #ffffff, $alpha: 0.1);
    }
  }

  .menu-dropdown {
    display: flex;
    flex-direction: column;
    background-color: rgba($color: #000000, $alpha: 0.2);
    font-size: 1rem;
  }

  .menu-dropdown_item {
    padding: 10px 13px;
    color: var(--primary-font-color);

    &:hover {
      background-color: var(--secondary);
      color: var(--secondary-font-color);
      text-decoration: none;
      cursor: pointer;
    }
  }
</style>
