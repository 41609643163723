export const parseAppRouteId = (appRouteId: string) => {
  const data = appRouteId.split('-')
  
  const [application, tenant] = data.splice(0, 2)
  const [country, touchpoint, environment, type] = data.splice(data.length - 4, 4)
  const customer = data.join('-')
  
  return {
    application,
    tenant,
    customer,
    country,
    touchpoint,
    environment,
    type,
  }
}
